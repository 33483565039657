<template>
    <Modal v-if="openModal">
        <Card class="flex p-4 flex-col max-w-md"
            style="min-width: 348px; max-height: 348px; overflow-y: auto"
        >
            <div class="text-darkPurple font-bold text-lg pb-3 flex gap-3 items-center border-b border-solid border-romanSilver">
                <div class="cursor-pointer"  @click="close()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div>Export Directory
            </div>
            <div class="w-full flex flex-col justify-center items-center gap-2 my-4">
                <img :src="require('@/assets/images/download-box.png')" alt="not_found">
                <p class="flex justify-center items-center text-center text-jet font-semibold">
                    Confirm you want to export employee directory
                </p>                         
            </div>
            <div class="w-full flex justify-between">
                <button
                    class="text-white rounded-lg bg-dynamicBackBtn w-1/2 h-11 flex justify-center items-center outline-none border-none"
                    @click="submit"
                    :disabled="isLoading"
                >
                    {{ isLoading ? 'Exporting...' : 'Yes, Export Directory' }}
                </button>
                <button
                    class="text-darkPurple font-semibold w-1/2 h-11 flex justify-center items-center outline-none border-none"
                    @click="close"
                    :disabled="isLoading"
                >
                    No, Cancel
                </button>
            </div>
        </Card>
    </Modal>
</template>
<script>
export default {
    name: "ExportDirectory",
    components: {
        Card: () => import("@/components/Card"),
        Modal: () => import("@/components/Modal")
    },
    props: {
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async submit() {
            this.isLoading = true;
            try {
                const { data }  = await this.$_exportDirectory();
                const blob = new Blob([ data ], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `Employees_Details`;
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(link);
                this.isLoading = false;
                this.close();
                return this.$toasted.success("Directory successfully exported", {duration: 6000});
            } catch (error) {
                this.isLoading = false;
                return this.$toasted.error("Erorr exporting directory", {duration: 6000});
            }
        }
    }
};
</script>
<style scoped>
</style>